<template>
  <div>
    <van-cell-group title="主要信息">
      <van-cell title="日期" v-model="info.CREATED_DTStr"/>
      <van-cell title="功能室" v-model="info.Func_Room_Name"/>
      <van-cell title="设施" v-model="info.Facillities"/>
      <van-cell title="教玩具" v-model="info.Room_Tools"/>
    </van-cell-group>
    <van-cell-group title="附加信息">
      <van-cell title="类型" v-model="info.Activity_Type"/>
      <van-cell title="活动内容" v-model="info.Activity_Content"/>
      <div v-if="info&&info.LST_Activity_Pics_URL.length>0" class="imgbox">
        <div class="title">活动照片</div>
        <van-image v-for="item in info.LST_Activity_Pics_URL" :key="item.Key" width="100" height="100"
                   style="margin: 0 5px 5px" :src="item.Value"/>
      </div>
      <van-cell v-else title="活动照片" value="无"/>
    </van-cell-group>
    <van-cell-group title="整理情况">
      <van-cell title="环境" v-model="info.Environment_State"/>
      <van-cell title="设施" v-model="info.Facility_State"/>
      <van-cell title="教玩具" v-model="info.Tool_State"/>
      <van-cell title="活动老师" v-model="info.CreateUserName"/>
      <van-field type="textarea" label="备注" v-model="info.Remark" placeholder="选择破损或损坏时备注说明情况"
                 input-align="right" readonly/>
      <div v-if="info&&info.LST_Organize_Pics_URL.length>0" class="imgbox">
        <div class="title">整理照片</div>
        <van-image v-for="item in info.LST_Organize_Pics_URL" :key="item.Key" width="100" height="100"
                   style="margin: 0 5px 5px" :src="item.Value"/>
      </div>
      <van-cell v-else title="整理照片" value="无"/>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "confirm",
  data() {
    return {
      info: '',
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let id = this.$route.query.id
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/FUNC/FuncRoomUse/Get?id=" + id,
        completed(its) {
          console.log(its)
          self.info = its.DATA
        }
      })
    },
  }
}
</script>
<style scoped>
.imgbox {
  padding: 10px;
}

.imgbox .title {
  padding: 0 6px;
  font-size: 14px;
  color: #646566;
  margin-bottom: 10px;
}
</style>
